import React, {Component} from 'react';
import arrow from '../assets/images/arrow.svg';

class About extends React.Component{
    render(){
        return(

        <div className="section" id="section2">
        <span className="bg-trans"></span>
            <div className="container">
                
                <div className="top-info inner">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                        
                            <h1>About me</h1>  
                                                    
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">

                        <p>I'm a UI/UX developer & designer based in Trivandrum, Kerala, India. With several years of professional experience in designing & developing websites, I specialize in Users. From Interfaces to Experiences my goal is to create things that seamlessly work. I enjoy turning ideas into intuitive Designs, pixel based solutions for the Web & web applications.</p>                     
                        
                        <a href="#Skills" className="btn btn-main p-0">Skills <img src={arrow} class="arrow"/></a>	
                        																                                             
                    </div>
                </div>
                
            </div>		
        </div>

        );
    }
}

export default About;