import React, {Component} from 'react';
import arrow from '../assets/images/arrow.svg';
import prodark from '../assets/images/pro-dark.png';

class Skills extends React.Component{
    render(){
        return(

        <div className="section" id="section3">
        <span className="bg-trans"></span>
            <div className="container">
            
                <div className="top-info inner">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                        
                            <h1>Skills</h1>  
                                                    
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">

                        <div className="row skills mb-30">
                            <div className="col-md-4">
                                <h3>Frontend</h3> 
                                
                                <ul>
                                    <li>HTML5</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark}  className="pro"/> <img src={prodark} className="pro light"/></li>
                                </ul>
                                
                                <ul>
                                    <li>CSS3 (SASS/LESS)</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/></li>
                                </ul>
                                
                                <ul>
                                    <li>Bootstrap 3/4</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/></li>
                                </ul>
                                
                                <ul>
                                    <li>React</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>

                                <ul className="d-none">
                                    <li>Vue.js</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>

                                <ul>
                                    <li>Javascript</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>

                                <ul>
                                    <li>jQuery</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>
                                
                                <div className="mt-15 d-none d-sm-block"><a href="#Contact" className="btn btn-main p-0">Contact <img src={arrow} className="arrow"/></a></div>
                                
                            </div>
                            
                            <div className="col-md-4">
                                <h3>CMS</h3> 
                                
                                <ul>
                                    <li>WordPress</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>

                                <ul>
                                    <li>October CMS</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>      
                                
                            </div>
                            
                            <div className="col-md-4">
                                <h3>Graphic</h3> 
                                
                                <ul>
                                    <li>Photoshop</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/></li>
                                </ul>
                                
                                <ul>
                                    <li>Illustrator</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>

                                <ul className="d-none">
                                    <li>Xd</li>
                                    <li><img src={prodark} className="pro"/> <img src={prodark} className="pro"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/> <img src={prodark} className="pro light"/></li>
                                </ul>
                                
                                <div className="mt-15 d-block d-sm-none"><a href="#Contact" className="btn btn-main p-0">Contact <img src={arrow} className="arrow"/></a></div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>

        );
    }
}

export default Skills;