import React, {Component} from 'react';
import Social from '../components/common/social/Social';

class Contact extends React.Component {

    state = {
        showLinkedin: 'L'
    }
    render() {
        return(

        <div className="section" id="section4">
        <span className="bg-trans"></span>
            <div className="container">
            
                <div className="top-info inner">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                        
                            <h1>Reach Me</h1>  
                                                    
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-12"><h3>Feel free to contact me</h3> </div>
                        </div>

                        <Social myLinkedin={this.state.showLinkedin}></Social>                        
                                                
                    </div>
                </div>
                
            </div>
        </div>    

        )
    }
}

export default Contact;