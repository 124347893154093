import React, {Component} from 'react';
import logo from '../assets/images/logo-white.svg';
import arrow from '../assets/images/arrow.svg';

class Intro extends React.Component{
    render(){
        return(

        <div className="section" id="section1">
        <span className="bg-trans"></span>
        <div className="logo-wrap"><a href="index.html"><img src={logo} alt="Logo" title="Praveen Raj"/></a></div>
	    	<div className="container">
            	
                <div className="top-info">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                        
                            <h1>Hello, I'm Praveen Raj<br/>
                            <span className="d-none" id="typewriter"> </span>
                            <a href="" class="typewrite" data-period="2000" data-type='[ "Frontend Developer", "UI/UX Designer"]'>
                                <span class="wrap"></span>
                            </a>
                            </h1>  
                                                    
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11">

   			        	<a href="#About" className="btn btn-main p-0">About me <img src={arrow} className="arrow"/></a>    
                                                
                    </div>
                </div>
            
            </div>
	    </div>

        );
    }
}

export default Intro;