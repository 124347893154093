import React, {Component} from 'react';

class Social extends React.Component{
    render(){
        return(

            <div className="row skills">
            <div className="col-md-4">
                
                <p>iamraj666@gmail.com</p>
                <p>98 47 34 24 23</p>
                <p>
				<a href="https://www.behance.net/iamraj666f271" target="_blank"><i class="fa fa-behance" aria-hidden="true"></i></a> &nbsp; 
				<a href="https://codepen.io/Prav_Raj" target="_blank"><i class="fa fa-codepen" aria-hidden="true"></i></a> &nbsp;
				<a href="#" target="_blank" className="d-none"><i class="fa fa-codepen" aria-hidden="true">{this.props.myLinkedin}</i></a>
				</p>

            </div>
            </div>

        );
    }
}

export default Social;