import React from 'react';
import Intro from './components/Intro';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
import './App.scss';
import parone from './assets/images/parallax-1.png';
import partwo from './assets/images/parallax-2.png';
import parthree from './assets/images/parallax-3.png';

function App() {
  return (
    <div className="App">
      <div id="pagepiling">

      <div className="svg-1"><img src={partwo}/></div>
      <div className="svg-2"><img src={parone}/></div>
      <div className="svg-3"><img src={parthree}/></div>

      <Intro></Intro>
      <About></About>
      <Skills></Skills>
      <Contact></Contact>
      
      </div>
    </div>
  );
}

export default App;
